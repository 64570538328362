<template>
    <router-link class="navbar-brand" :to="to">{{ brand }}</router-link>
</template>

<script>
    import { computed } from "@vue/reactivity";
    import { useRoute } from "vue-router";

    export default {
        name: "NavBarBrand",
        props: {
            to: String,
            brand: String,
        },
        setup(props) {
            const route = useRoute()
            const isActive = computed(() => route.path === props.to)
            return { isActive }
        }
    }
</script>
