<template>
    <a class="nav-link fs-6 btn btn-outline-danger p-2 text-center text-danger" :href="tel">{{ text }}</a>
</template>

<script>
    export default {
        name: "NavBarTel",
        props: {
            tel: String,
            text: String,
        }
    }
</script>