<template>
    <li :class="{ 'nav-item': navitem }">
          <router-link 
            :class="{ 'dropdown-item': dropdown, 'nav-link': navlink, 'active': isActive }" aria-current="page" :to="to">{{ text }}</router-link>
    </li>
</template>

<script>
    import { computed } from "@vue/reactivity";
    import { useRoute } from "vue-router";

    export default {
        props: {
            navitem: {
                type: Boolean,
                required: false,
                default: true,
            },
            navlink: {
                type: Boolean,
                required: false,
                default: true,
            },
            dropdown: {
                type: Boolean,
                required: false,
                default: false,
            },
            to: {
                type: String,
                required: true,
            },
            text: {
                type: String,
                required: true,
            }
        },
        setup(props) {
            const route = useRoute()
            const isActive = computed(() => route.path === props.to)
            return { isActive }
        }
    }
</script>