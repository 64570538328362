<template>
  <!-- 
  <button 
    class="btn btn-primary" 
    data-bs-target="#collapseTarget" 
    data-bs-toggle="collapse">
    Bootstrap collapse
  </button>
  <div class="collapse py-2" id="collapseTarget">
    This is the toggle-able content!
  </div> 
  -->
  <nav class="navbar navbar-expand-lg navbar-light m-0 border shadow">
    <div class="container-fluid">
      <NavBarBrand to="/" brand="Air Services"/>
      <button class="navbar-toggler" type="button" data-bs-target="#collapseTarget" data-bs-toggle="collapse" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="collapseTarget">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <NavBarLink to="/inspection-and-repair" text="Inspection & Repair" />
            <NavBarLink to="/training" text="Training" />
            <NavBarLink to="/about" text="About" />
            <NavBarLink to="/contact" text="Contact" />
          </ul>
          <div class="d-flex">
            <NavBarTel tel="tel:970-901-2230" text="970-901-2230"/>
          </div>
      </div>
    </div>
  </nav>
</template>
<li class="nav-item"><a class="nav-link fs-6" href="tel:970-901-2230">970-901-2230</a></li>
<script>
import NavBarLink from './NavBarLink.vue'
import NavBarTel from './NavBarTel.vue'
import NavBarBrand from './NavBarBrand.vue'

export default {
    props: {},
    components: { NavBarLink, NavBarTel, NavBarBrand }
}
</script>

<style>
  .navbar-light {
    background-color: white !important;
  }
</style>