import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/inspection-and-repair',
    name: 'Inspection & Repair',
    component: () => import('../views/InspectionRepairVue.vue')
  },
  {
    path: '/training',
    name: 'Training',
    component: () => import('../views/TrainingVue.vue')
  },

  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/ContactVue.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  // history: createWebHistory(process.env.BASE_URL),
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
