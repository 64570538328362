<template>
  <div class="d-flex flex-column min-vh-100 p-4 justify-content-center text-white align-items-center">
    <div class="col-lg-12 mb-4">
      <div class="border bg-white shadow">
        <div class="position-relative overflow-hidden p-md-5 text-center bg-image" style="
          padding-top: 10rem;
          padding-bottom: calc(10rem - 4.5rem);
          background-position: center;
          background-repeat: no-repeat;
          background-attachment: scroll;
          background-size: cover;
          ">
          <div class="col-md-8 p-lg-5 mx-auto my-2">
            <img src="logo_transparent.png" class="image-fluid w-25 mb-2">
            <h1 class="display-4 fw-normal">Air Services LLC</h1>
            <hr class="divider divider-light">
            <div class="col-lg-12 align-self-baseline">
              <div class="row">
                <h3 class="col-lg-3">Inspection</h3>
                <h3 class="col-lg-3">Repair</h3>
                <h3 class="col-lg-3">Instruction</h3>
                <h3 class="col-lg-3">Rental</h3>
              </div>
            </div>
          </div>
        </div>
        <section class="p-4" id="services">
          <div class="container px-4 px-lg-5 text-dark">
            <h2 class="text-center mt-2">SERVICES</h2>
            <hr class="divider" />
            <div class="row gx-4 gx-lg-5">
              <div class="col-lg-4 col-md-4 text-center">
                <div class="mt-5">
                  <h3 class="h4 mb-2">Annuals</h3>
                  <hr class="divider" />
                  <p class="mb-0">Our #1 priority is your safety, get a complete and comprehensive inspection with Air
                    Services</p>
                  <ButtonItem to="/inspection-and-repair" color="btn-danger" text="Learn More" />
                </div>
              </div>
              <div class="col-lg-4 col-md-4 text-center">
                <div class="mt-5">
                  <h3 class="h4 mb-2">Maintenance</h3>
                  <hr class="divider" />
                  <p class="mb-0">No job is too big or too small. Ensure your aircraft gets quality airworthiness
                    maintenance.</p>
                  <ButtonItem to="/inspection-and-repair" color="btn-danger" text="Learn More" />
                </div>
              </div>
              <div class="col-lg-4 col-md-4 text-center">
                <div class="mt-5">
                  <h3 class="h4 mb-2">Rental</h3>
                  <hr class="divider" />
                  <p class="mb-0">Rent from Air Services, knowing your plane gets serviced each trip from the best in
                    the business!</p>
                  <a href="https://bookourplane.com/" target="_blank" class="btn btn-dark mt-2">Book Now</a>
                </div>
              </div>
            </div>
            <div class="row gx-4 gx-lg-5">
              <div class="col-lg-4 col-md-4 text-center">
                <div class="mt-5">
                  <h3 class="h4 mb-2">Private Pilot License Instruction</h3>
                  <hr class="divider" />
                  <p class="mb-0">Get your Private Pilot's License with the best Certified Flight Instructor in the
                    West! </p>
                  <ButtonItem to="/training" color="btn-danger" text="Learn More" />
                </div>
              </div>
              <div class="col-lg-4 col-md-4 text-center">
                <div class="mt-5">
                  <h3 class="h4 mb-2">Instrument Rating Instruction</h3>
                  <hr class="divider" />
                  <p class="mb-0">Instrument Rating (IR) is one of the most valuable ratings you can add to your pilot
                    certificate.</p>
                  <ButtonItem to="/training" color="btn-danger" text="Learn More" />
                </div>
              </div>
              <div class="col-lg-4 col-md-4 text-center">
                <div class="mt-5">
                  <h3 class="h4 mb-2">Commercial License Instruction</h3>
                  <hr class="divider" />
                  <p class="mb-0">Get compensated for doing what you love by getting your Commercial Pilot License!</p>
                  <ButtonItem to="/training" color="btn-danger" text="Learn More" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="p-4 px-lg-5 bg-dark">
          <div class="row gx-4 gx-lg-5 justify-content-center">
            <div class="col-lg-12 text-center">
              <h2 class="text-white mt-0">We've got what you need!</h2>
              <hr />
              <p class="text-white-75">Air Services is licensed and certified in aircraft inspection/repair & flight
                instruction.</p>
              <p class="text-white-75 mb-4">Located in Blanding, Utah.</p>
              <form class="mb-4" @submit.prevent="handleSubmit" method="POST" netlify-honeypot="bot-field"
                data-netlify="true" id="form-subscribe" name="form-subscribe">
                <div class="row">
                  <div class="col-lg-6 my-2">
                    <input v-model="form.email" name="email" type="email" class="form-control" id="email"
                      placeholder="name@example.com">
                  </div>
                  <div class="col-lg-6 my-2">
                    <button type="submit" class="btn btn-light w-100">Get in Touch!</button>
                  </div>
                </div>
              </form>
              <div class="row mb-4">
                <div class="col-md-4">
                  <i class="bi-envelope fs-2 mb-3 text-white"></i>
                  <div><a href="mailto:chris@airservicesllc.com"
                      class="btn btn-link text-white">chris@airservicesllc.com</a></div>
                </div>
                <div class="col-md-4">
                  <i class="bi-phone fs-2 mb-3 text-white"></i>
                  <div><a href="tel:970-901-2230" class="btn btn-link text-white">970-901-2230</a></div>
                </div>
                <div class="col-md-4">
                  <i class="bi-envelope fs-2 mb-3 text-white"></i>
                  <div><a href="mailto:eva@airservicesllc.com"
                      class="btn btn-link text-white">eva@airservicesllc.com</a></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Form Successfully Submitted</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body text-center">
          Someone will contact you shortly!
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap'
import ButtonItem from '../components/ButtonItem'

export default {
  data: () => ({
    form: {
      email: '',
    },
    banner: {
      url: '@/assets/images/banner.jpeg'
    }
  }),
  components: {
    ButtonItem
  },
  methods: {
    encode(data) {
      return Object.keys(data)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
        .join('&')
    },
    handleSubmit() {
      fetch('/', {
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: this.encode({
          'form-name': 'form-subscribe',
          ...this.form
        })
      })
        .then(function () {
          document.getElementById("form-subscribe").reset();
          let myModal = new Modal(document.getElementById('exampleModal'), {});
          myModal.show();
        })
        .catch(e => console.error(e))
    }
  }
}
</script>

<style scoped>
.bg-image {
  background: linear-gradient(to bottom, rgba(92, 77, 66, 0.8) 0%, rgba(92, 77, 66, 0.8) 100%), url('@/assets/images/banner.jpeg');
}

hr.divider {
  height: 0.2rem;
  max-width: 3.25rem;
  margin: 1.5rem auto;
  background-color: #3a3e66;
  opacity: 1;
}

hr.divider-light {
  background-color: #fff;
}
</style>