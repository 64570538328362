<template>
    <router-link :class="color" class="btn mt-2" :to="to">{{ text }}</router-link>
    <!-- <button :class="{
        'bg-gray-100': true,
        'bg-red-900': true,
        'text-gray-900': true,
    }" class="rounded text-gray-900 p-1 w-full">{{ text }}</button> -->

    <!-- <div class="flex space-x-2 justify-center"> -->
    <!-- <button type="button" class="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">Button</button> -->
    <!-- </div> -->
</template>

<script>
export default {
    name: "ButtonItem",
    props: {
        to: String,
        text: String,
        color: String,
    }
}
</script>