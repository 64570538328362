<template>
  <NavBar />
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import NavBar from '@/components/sidebar/NavBar'

export default {
  components: {
    NavBar
  },
  setup() {
    return {}
  }
}
</script>

